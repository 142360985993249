<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'
import alertModelHelper from '../../../../../models/Helpers/alertModelHelper'
import { getAutoplugRuleInfo } from '@/components/AlertingComponents/Sheet/SheetFragments/AlertTypeTemplate/TemplateCommonLogic/commonLogicAutoplugAlert'

export default {
  name: 'InstructionInDraft',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Link to surcouche',
          value: (alert) => {
            let link = alertModelHelper.generateSimpleLinkToSurcouche(alert, this.$_FRONT_HOST)
            return `<a target="_blank" rel="noopener noreferrer" href="${link}">link to surcouche</a>`
          }
        },
        {
          label: 'Instruction id in draft',
          value: (alert) => {
            if (this.$commonUtils.issetInObject(alert, 'data.instruction_id_in_draft')) {
              return this.$commonUtils.htmlEntitiesList(alert.data.instruction_id_in_draft).join(',')
            }

            return 'NC'
          }
        },
        { label: 'Brief Id', value: 'data.overview_id' },
        {
          label: 'Autoplug rule',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(getAutoplugRuleInfo(alert))
          }
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
